import React, { useState } from 'react'
import introLtv from '../../img/gif/logo ltv.gif';
import { useNavigate } from 'react-router-dom';

export default function IntroLtv() {

    const navigate = useNavigate()

    setTimeout( () => {
        navigate("/")
    }, 6800);

  return (
    <>
        <div className="intro-pwa">
            <img className='' src={introLtv} alt="ltv intro" />
        </div> 
    </>
  )
}
