import React, { useEffect } from 'react';
import {useLocation} from 'react-router-dom';
import $ from "jquery";
// import YouTube from 'react-youtube';
import ReactPlayer from 'react-player/youtube'


export default function PopupEpisode({urlVideo, setUrlVideo}) {
    // const location = useLocation()

    const opts = {
        height: window.innerHeight - 200,  
        width: window.innerWidth -200,
        playerVars: {
            autoplay: 1,
        },
    };

    function togglePopUp(className) {
        $(`.${className}`).toggle();
    };
    
    function popupClose(e, className) {
        if (e.target.classList.contains("containerBox")) {
            // window.history.replaceState("","Title", " ");
            setUrlVideo("")
            togglePopUp(className);
            return true
        };
        return false  
    };

    function onReady(e) {
        e.target.playVideo();
    }
    
    // useEffect(() => {
    //     console.log("tmm");
    // }, [urlVideo])
    

return (
    <>
        <div className='popup-video'>
        <div  className='containerBox' onClick={(e) => {popupClose(e, "popup-video")} }>
            <div className='lightContainer' >
                {urlVideo ? <>
                {/* <YouTube   videoId={videoId}  opts={opts}  onReady={onReady} /> */}
                {/* window.innerWidth < 600 ? window.innerWidth - 50 : window.innerWidth - 300  */}
                <ReactPlayer controls={true} width={"100%"} height={"100%"} url={urlVideo} />

                </> : <></>}
            </div>
        </div>
    </div>


    </>
)
}
