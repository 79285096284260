import React, { useEffect } from 'react'
import ReactGA4 from 'react-ga4';
import { useLocation } from 'react-router-dom';

export default  function GoogleAnalyticsApi() {
    const googleApiKey = "AIzaSyBRegZg4K8qQR1ED5A8ne8WuCb86wEiOEQ";
    const trackingId = "G-BVEGKNTFJX";
    
    
    const location = useLocation();

    useEffect(() => {
        // Send pageview with a custom path
        ReactGA4.send({ hitType: "pageview", page: location.pathname + location.search });
    }, [location]);
  
    useEffect(() => {
        // Initialize React Ga with your tracking ID
        if (process.env.NODE_ENV === 'production') ReactGA4.initialize(trackingId);
    }, []);
}

const TrackGoogleAnalyticsEvent = (category, action, label) => {
    // Track a custom event
    ReactGA4.event({
      category,
      action,
      label,
    });
    // Continue with your button's click handler
}
export { TrackGoogleAnalyticsEvent };