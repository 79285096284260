import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player/lazy'
import { Player } from 'video-react';
import blogImg1 from "../../img/blog/blog-img-1.jpeg";
import { useApi } from '../Store/ApiContext';
import moment from 'moment';
import LoginForm from '../LoginForm/LoginForm';
import $ from 'jquery';


export default function Broadcasting() {
    const [streamUrl, setStreamUrl] = useState('');

    const {getData, token} = useApi();
  
    const [data, setData] = useState([]);
    // const [broadcastLink, setBroadcastLink] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
  
    async function fetchData() {
        const data = await getData(`liveBroadcast`);
        setStreamUrl(data.broadcastLink)
        let listOfBroadcastingDay = hourOfDay(data.seasonsLiveBroadcast)
        listOfBroadcastingDay.sort(compareTime)
        setData(listOfBroadcastingDay)
    }

    function compareTime(a, b) {
        const momentA = moment(a.startBroadcastTime, "HH:mm:ss");
        const momentB = moment(b.startBroadcastTime, "HH:mm:ss");
        if (momentA.isBefore(momentB)) {
            return -1;
        } else if (momentA.isAfter(momentB)) {
            return 1;
        } else {
            return 0;
        }
    }

    function hourOfDay(data) {
        let dayBroadcasting = [];
        data.forEach(ele => {
            let t = ele.broadcastDay.split(",").find((d) => parseInt(d) === moment().day())
            if (t) {
                dayBroadcasting.push(ele)
            }
        });
        return dayBroadcasting;
    }
  
    useEffect(() => {
        fetchData()
    }, []);

  

    const handleUrlChange = (event) => {
        setStreamUrl(event.target.value);
    };

    
    // useEffect(() => {
    //     if (false) {
    //         $("body").css({"overflow-y" : "hidden"})
    //     }
    //     // return () => {
    //     //     $("body").css({"overflow-y" : "scroll"})
    //     // }
    // }, [])

  return (
    <>
    <section className='broadcasting'>
        <div className='container'>
            <div>
                <h2 className='fw-bolder text-center d-inline-block d-lg-none fs-3 pt-3 mb-4'>
                    البث المباشر
                </h2>
            </div>
            <div className="row justify-content-start align-items-start justify-content-start">
                <div className="col-lg-3" dir='ltr'>
                    <div className='timeline-broadcasting scrollbar-style'>
                        {data?.length ? data.map((ele) =>
                            <div className='item-timeline'>
                                <p className='fw-bold'>{ele.seasonName}</p>
                                <p className='me-lg-4'>{moment(ele.startBroadcastTime, "HH:mm").format("HH:mm")}</p>
                            </div> 
                            ) : <></>}
                    </div>
                </div>
                <div className="col-lg-8">    
                    <div className='player-wrapper mt-lg-0 mt-3 text-center'>
                        <ReactPlayer
                            url={streamUrl} 
                            controls  
                            className='react-player'
                            // pip={true}
                            // light={true}
                            // url='https://eitc3.secure2.footprint.net/hls/LTV3.m3u8'
                            // width='100%'
                            // height='100%'
                            // style={{
                            //     backgroundColor: '#f5f5f5',
                            //     borderRadius: '5px',
                            //     boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                            //   }}
                            
                        />                        
                        {/* <Player
                            playsInline
                            // poster="/assets/poster.png"
                            src="https://eitc3.secure2.footprint.net/hls/LTV3.m3u8"
                        /> */}
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* {!token ? 
    <div className='overlay-login'>
        <LoginForm/>
    </div>
    : <></>} */}
    </>
  )
}
