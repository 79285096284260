import React, { useEffect } from 'react';
import {useLocation} from 'react-router-dom';
import $ from "jquery";
import LoginForm from '../LoginForm/LoginForm';


export default function PopupLogin({}) {

    function togglePopUp(className) {
        $(`.${className}`).toggle();
    };
    
    function popupClose(e, className) {
        if (e.target.classList.contains("containerBox")) {
            togglePopUp(className);
            return true
        };
        return false  
    };

return (
    <>
        <div className='popup-login'>
        <div  className='containerBox' onClick={(e) => {popupClose(e, "popup-login")} }>
            <div className='lightContainer' >
            <LoginForm />
            </div>
        </div>
    </div>


    </>
)
}
