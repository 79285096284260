import React from 'react'
import loader from '../../assets/TtclgQEtyQ.json';
import { Player } from '@lottiefiles/react-lottie-player';

export default function Loader() {
  return (
    <Player
          autoplay
          loop
          src={loader}
          style={{ height: '300px', width: '300px'}}
        >
    </Player>
  )
}
