import React, { useState } from 'react'
import { useEffect } from 'react';
import MediaEpisodes from '../MediaEpisodes/MediaEpisodes';
import OverlayShareBtn from '../OverlayShareBtn/OverlayShareBtn';
import Loader from '../Loader/Loader';

export default function YoutubePlaylistItems({playlistId, openVideo}) {
    const [playlistVideos, setPlaylistVideos] = useState([]);
    const [playlistVideosSorted, setPlaylistVideosSorted] = useState([]);
    const [nextPage, setNextPage] = useState("");
    const apiKey = "AIzaSyBRegZg4K8qQR1ED5A8ne8WuCb86wEiOEQ";
    // const playlistId = "PLytl60CPXTOwfeR3fDIudjyL4GIBlivkK";

    const fetchPlaylistVideos = async () => {
      try {
        const response = await fetch(`https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${playlistId}&pageToken=${nextPage}&maxResults=50&key=${apiKey}`);
        const data = await response.json();
        console.log(data);
        setPlaylistVideos((prevResult) => [...prevResult, ...data.items]);
        data.nextPageToken ? setNextPage(data.nextPageToken) : sortPlaylistDependPublishDate([...playlistVideos, ...data.items]);
      } catch (error) {
        console.error('Error fetching playlist videos:', error);
      }
    };

    function sortPlaylistDependPublishDate(playlist) {
      console.log(playlist);
      // Sorting the array based on the publishedAt property
      playlist.sort((a, b) => new Date(a.publishedAt) - new Date(b.publishedAt));

      setPlaylistVideosSorted(playlist);
      setNextPage("")
    }

  useEffect(() => {
    setNextPage("");
    setPlaylistVideos([]);
    fetchPlaylistVideos();
  }, [playlistId]);

  useEffect(() => {
    if (playlistVideos.length && nextPage) {
      fetchPlaylistVideos();
    }
  }, [nextPage]);
  
  useEffect(() => {
    if (playlistVideosSorted.length) {
      setPlaylistVideos([]);
    }
  }, [playlistVideosSorted])
  
  useEffect(() => {
    return () => {
      setPlaylistVideos([]);
      setNextPage("")
    }
  }, []);

  

  return (
    <>
    {/* <section className='mt-5'>
      <ul>
        {playlistVideos && playlistVideos?.map(video => (
          <li key={video.snippet.resourceId.videoId}>
            <img src={video.snippet.thumbnails.default?.url} alt={video.snippet.title} />
            <p>{video.snippet.title}</p>
          </li>
        ))}
      </ul>
    </section> */}
    <section className='media-episode'>
        <div className='container-fluid'>
            <div className='row gy-5 gx-2'>
                {playlistVideosSorted.length ? playlistVideosSorted?.map((ele, i) => <>
                {ele.snippet.title !== "Private video" ? 
                  <div className='col-xxl-2 col-xl-3 col-lg-4 col-sm-6 py-xl-2 py-4'>
                  <div className='episode' >
                      <div className='header-card' onClick={() => {openVideo('https://www.youtube.com/watch?v=' + ele.snippet.resourceId.videoId)}}>
                          <img className='w-100 img-test' src={ele.snippet.thumbnails.high?.url} alt={ele.snippet.title} />
                          <div className="layer-info-episode">
                              <div className="info-episode d-flex justify-content-between align-items-baseline">
                                  <span className='number-episode'>{(i + 1).toLocaleString('ar-EG')}</span>
                              {/* <p className='duration-episode'>{convertSecondsToMinutesAndSeconds(ele.episodeDuration)}</p> */}
                              </div>
                              
                          </div>
                          <div className='info-without-hover'>
                              <p className='title-episode'>{ele.snippet.title.slice(0,90)}...</p>
                          </div>
                          
                      </div>
                      <div className='info-with-hover'>
                          <p className='title-episode'>{ele.snippet.title}</p>
                          <div className='d-flex justify-content-between episode-btn'>
                              <div className='d-flex align-items-center play-media-episode' onClick={() => {openVideo('https://www.youtube.com/watch?v=' + ele.snippet.resourceId.videoId)}}>
                                  <svg className='img-play-media-item' xmlns="http://www.w3.org/2000/svg" width="1050" height="1143" viewBox="0 0 1050 1143" fill="none">
                                      <path d="M344.781 308.516C296.211 321.368 263.111 392.369 251.957 507.543C248.959 538.114 248.959 606.744 251.957 637.316C263.111 752.864 295.851 822.991 345.141 836.343C366.608 842.332 412.78 831.601 451.157 812.01C481.019 796.662 537.385 757.855 579.479 723.665C657.432 660.276 706.362 604.623 709.241 575.798C711.039 557.081 695.089 531.126 657.432 491.945C635.845 469.484 610.18 446.025 581.878 423.19C506.803 362.297 442.402 323.864 394.191 310.762C379.68 306.893 355.215 305.77 344.781 308.516Z" fill="#8F55F2"/>
                                      <path d="M216.348 3.46466C196.076 8.56864 183.475 14.4785 164.298 27.6414C81.8411 85.6656 26.2302 226.696 5.13647 432.199C-1.71216 498.013 -1.71216 645.76 5.13647 711.574C29.2436 947.7 99.6475 1098.94 202.103 1135.74C230.319 1145.68 276.068 1145.41 323.187 1134.94C394.138 1119.09 465.09 1089.81 527.276 1051.12C703.422 941.253 827.793 846.695 932.714 743.81C1017.36 660.803 1053.8 604.659 1049.69 564.634C1042.84 498.55 914.634 365.041 721.776 222.936C551.383 97.4853 410.849 22.806 308.394 4.00188C281.547 -1.1021 235.798 -1.37068 216.348 3.46466ZM301.819 119.513C358.252 131.87 468.377 186.939 563.71 250.336C599.871 274.244 673.836 327.701 705.34 352.415C800.125 427.363 896.827 518.429 923.948 557.918L933.536 571.886L922.852 587.467C908.059 608.689 819.301 695.725 771.36 735.751C600.967 878.125 442.627 978.324 328.665 1015.66C302.093 1024.53 290.861 1026.95 269.219 1028.02C243.743 1029.1 241.825 1028.83 231.415 1022.11C192.241 996.86 152.793 901.765 133.069 786.254C113.619 672.892 110.879 518.966 126.22 402.112C144.848 257.858 190.323 142.078 236.894 118.438C247.578 113.066 251.687 112.529 267.028 113.872C276.89 114.946 292.505 117.364 301.819 119.513Z" fill="#8F55F2"/>
                                  </svg>
                                  <svg className='img-play-media-item-hover' width="841" height="942" viewBox="0 0 841 942" fill="none">
                                      <g clip-path="url(#clip0_232_504)">
                                      <path d="M128.877 1.94717C115.989 7.72322 105.782 16.181 94.8535 30.2086C56.2935 79.5113 22.8886 185.853 8.35123 305.603C1.64963 360.991 0 392.656 0 468.57C0 541.802 1.54652 574.808 7.83572 631.021C24.6413 782.849 72.3773 911.882 121.66 938.596L127.846 942H146.92C168.056 941.897 178.057 940.762 194.862 936.327C305.49 907.137 471.896 806.366 649.025 661.346C692.74 625.658 716.969 603.895 758.725 562.946C804.811 517.769 824.71 496.316 835.536 480.122L841 472.077L835.536 464.135C818.73 439.69 793.264 411.738 752.745 373.265C690.781 314.576 633.353 265.995 572.73 220.818C470.453 144.698 401.787 100.656 320.646 58.9857C242.701 19.069 191.666 1.63774 147.951 0.193724C135.166 -0.218852 133.62 -0.115708 128.877 1.94717ZM287.138 230.823C320.852 238.147 363.33 259.085 414.984 293.741C461.277 324.89 504.27 359.547 539.531 394.306C582.112 436.08 597.268 461.04 591.803 480.019C580.978 517.46 502.93 591.414 406.324 655.776C353.432 690.948 324.255 704.666 285.592 712.402C261.569 717.146 247.547 716.115 232.803 708.482C194.862 688.679 170.015 634.528 158.777 546.959C155.683 523.03 154.962 503.226 155.477 460.318C155.993 416.173 156.611 407.715 161.87 375.328C175.376 292.606 204.244 241.963 244.866 230.204C250.537 228.554 278.581 228.967 287.138 230.823Z" fill="#8F55F2"/>
                                      </g>
                                      <defs>
                                      <clipPath id="clip0_232_504">
                                      <rect width="841" height="942" fill="white"/>
                                      </clipPath>
                                      </defs>
                                  </svg>
                                  <p className='title-play-media-item ms-1'>شاهد الأن</p>
                              </div>
                              <OverlayShareBtn urlShare={'https://www.youtube.com/watch?v=' + ele.snippet.resourceId.videoId} imageUrl={ele.snippet.thumbnails.medium?.url}>
                                  <div className='share-btn-card d-flex align-items-center '>
                                      <div className='icon-share-card d-flex align-items-center'>
                                          <svg className='icon-share' xmlns="http://www.w3.org/2000/svg" width="683" height="572" viewBox="0 0 683 572" fill="none">
                                              <path d="M455.467 1.83337C444.667 5.30004 436.267 14.7667 431.733 28.7667C429.2 36.1 428.8 41.3 428.267 70.1C427.6 107.3 428.667 104.5 416 104.5C406.533 104.5 378.4 108.233 360 111.967C321.333 119.567 287.467 131.3 250 149.833C205.867 171.567 171.867 195.7 136.8 229.833C97.6 268.1 69.8667 305.967 46.6667 353.033C17.4667 412.233 2.80003 469.833 0.533367 534.5C-0.1333 553.567 3.37074e-05 556.367 2.2667 560.5C7.20003 569.967 19.8667 574.1 29.0667 569.3C31.2 568.233 38.4 560.367 45.2 551.833C117.467 461.433 214.533 399.167 324.667 372.5C355.867 364.9 383.733 360.5 414.8 358.367L427.733 357.433L428.267 390.633C429.067 431.033 430.533 437.167 441.867 449.567C448.933 457.167 455.333 459.833 466.667 459.7C478.4 459.567 484.8 457.033 496.667 448.1C501.067 444.767 541.333 405.3 586.133 360.233C655.067 290.9 668.133 277.3 671.733 270.233C685.333 244.1 686 218.633 673.733 193.833C669.067 184.233 664.8 179.7 581.467 96.3667C483.867 -1.1 487.333 1.83337 468.667 1.03337C463.6 0.766702 457.6 1.1667 455.467 1.83337ZM557.467 128.1C618.8 189.7 636.267 207.967 638.8 213.167C643.467 223.033 643.333 238.5 638.4 247.833C635.867 252.633 612.533 276.767 552.8 336.5C507.733 381.567 470.267 417.967 469.733 417.3C469.2 416.767 468.4 396.9 468 373.3C467.2 325.833 467.333 325.967 458.267 320.5C447.867 314.1 379.333 319.3 330 330.1C228.267 352.233 137.2 401.3 60.5334 475.433L44.4 491.167L46.6667 478.1C71.2 341.3 167.867 223.567 298 172.1C342.4 154.5 383.6 145.833 434.8 143.433C457.6 142.367 460.533 141.3 465.333 133.433C467.867 129.433 468 126.5 468 88.5C468 66.1 468.4 46.5 468.8 44.9L469.6 41.8333L474.533 45.7C477.2 47.8333 514.533 84.9 557.467 128.1Z" fill="#8F55F2"/>
                                          </svg>
                                          <svg className='icon-share-hover' xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 683 572" fill="none">
                                              <path d="M455.467 1.83337C444.667 5.30004 436.267 14.7667 431.733 28.7667C429.2 36.1 428.8 41.3 428.267 70.1C427.6 107.3 428.667 104.5 416 104.5C406.533 104.5 378.4 108.233 360 111.967C321.333 119.567 287.467 131.3 250 149.833C205.867 171.567 171.867 195.7 136.8 229.833C97.6 268.1 69.8667 305.967 46.6667 353.033C17.4667 412.233 2.80003 469.833 0.533367 534.5C-0.1333 553.567 3.3687e-05 556.367 2.2667 560.5C7.20003 569.967 19.8667 574.1 29.0667 569.3C31.2 568.233 38.4 560.367 45.2 551.833C117.467 461.433 214.533 399.167 324.667 372.5C355.867 364.9 383.733 360.5 414.8 358.367L427.733 357.433L428.267 390.633C429.067 431.033 430.533 437.167 441.867 449.567C448.933 457.167 455.333 459.833 466.667 459.7C478.4 459.567 484.8 457.033 496.667 448.1C501.067 444.767 541.333 405.3 586.133 360.233L667.733 278.5L673.733 265.833C685.6 241.167 685.6 217.833 673.733 193.833C669.067 184.233 664.8 179.833 581.467 96.3667C483.867 -1.1 487.333 1.83337 468.667 1.03337C463.6 0.766702 457.6 1.1667 455.467 1.83337Z" fill="#8F55F2"/>
                                          </svg>
                                      </div>
                                      <p className='title-share-card ms-1'>شارك</p>
                                  </div>
                              </OverlayShareBtn>
                          </div>
                      </div>
                  </div>
              </div>
                : <></>}
                    </>) : <Loader />}
            </div>
        </div>
    </section>
    </>
    
    
  );
};
