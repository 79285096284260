import React, { useEffect, useState } from 'react'
import { useApi } from '../Store/ApiContext';
import { useForm, Controller } from 'react-hook-form';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import Select from 'react-select';
import { Bounce, toast } from 'react-toastify';

export default function RegistrationForm() {
    
    const { responseApi, setToken } = useApi();

    const navigate = useNavigate();

    const arabicCountries = [
        { value: "BH", label: "البحرين" },
        { value: "EG", label: "مصر" },
        { value: "IQ", label: "العراق" },
        { value: "JO", label: "الأردن" },
        { value: "KW", label: "الكويت" },
        { value: "LB", label: "لبنان" },
        { value: "LY", label: "ليبيا" },
        { value: "MR", label: "موريتانيا" },
        { value: "MA", label: "المغرب" },
        { value: "OM", label: "عُمان" },
        { value: "PS", label: "فلسطين" },
        { value: "QA", label: "قطر" },
        { value: "SA", label: "السعودية" },
        { value: "SD", label: "السودان" },
        { value: "SY", label: "سوريا" },
        { value: "TN", label: "تونس" },
        { value: "AE", label: "الإمارات" },
        { value: "YE", label: "اليمن" }
    ];
    
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        control,
        watch,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        const resData = await handleApiResponse(data);
        // console.log(resData);
        // if (resData) {
        //     resetInputs()
        // }
    }

    function resetInputs() {
        reset(
            {
                firstName : "",
                lastName : "",
                email : "",
                phoneNumber : "002",
                country : "",
                password : "",
                confirmPassword : "",
                subscription : "",
            }
        )
    }

    async function handleApiResponse(data) {
        console.log(data);
        const result = await responseApi("member/registration", "post", {...data, subscription : "free", country : data.country.value})
        console.log(result);
        let toastMessage = 'لقد حدث خطأ'
        if (result?.message === "success") {
            localStorage.setItem("token", result.token)
            setToken(result.token)
            navigate("/");
            toastMessage = 'تم تسجيل الحساب بنجاح و سيتم ارسال ايميل لتفعيل الحساب';
        }else if (result?.message === "There is an account with this email or mobile number registered before") {
            toastMessage = 'يوجد حساب مسجل بالفعل لهذا البريد الإلكتروني او رقم الموبايل';
        } else {
            toastMessage = 'لقد حدث خطأ';
        }
        toast(toastMessage, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
        });
        // return result;
    }

    


    
    return (
        <>
        <div className='registration container-fluid'>
            <div className='registration-form'>
                <div className='mb-5'>
                    <h3 className='login-title'>انشاء حساب</h3>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ul className='row g-2'>
                        <li class="mb-2 col-md-6 col-12">
                            <input className='input-form' 
                                type="text" 
                                name="firstName" 
                                placeholder="الأسم الأول"
                                {...register("firstName", { required: true })}
                            />
                            {errors.firstName && errors.firstName.type === "required" && (
                            <div className='text-danger'>الاسم الاول مطلوب</div>
                            )}
                        </li>
                        <li class="mb-2 col-md-6 col-12">
                            <input className='input-form' 
                                type="text" 
                                name="lastName" 
                                placeholder="الأسم الأخير"
                                {...register("lastName", { required: true })}
                            />
                            {errors.lastName && errors.lastName.type === "required" && (
                            <div className='text-danger'>الاسم الاخير مطلوب</div>
                            )}
                        </li>
                        <li class="mb-2 col-12">
                            <input className='input-form' 
                                type="text" 
                                name="email" 
                                placeholder="البريد الالكتروني"
                                {...register("email", { required: true, pattern : /^[\w\.]+@([\w-]+\.)+[\w-]{2,4}$/ })}
                            />
                            {errors.email && errors.email.type === "required" && (
                            <div className='text-danger'>البريد الالكتروني مطلوب</div>
                            )}
                            {errors.email && errors.email.type === "pattern" && (
                                <div className='text-danger'>رجاء وضع ابريد الالكتروني صحيح</div>
                            )}
                        </li>
                        <li class="mb-2 col-md-6 col-12">
                            {/* <input className=' w-100' 
                                type="phoneNumber" 
                                name="phoneNumber" 
                                placeholder="رقم التليفون" 
                                {...register("phoneNumber", { required: true })}
                            /> */}
                            <div dir='ltr' className='phone-input' >
                                <Controller
                                name="phoneNumber"
                                control={control}
                                rules={{
                                required: "رجاء إدخال رقم الهاتف مع رمز البلد",
                                pattern : {
                                    value : /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                                    message : "رجاء إدخال رقم الهاتف مع رمز البلد"
                                }
                                // maxLength: {
                                //     value: 12,
                                //     message : "رجاء إدخال رقم الهاتف مع رمز البلد (12 digits)"
                                // },
                                // minLength: {
                                //     value: 12,
                                //     message : "رجاء إدخال رقم الهاتف مع رمز البلد (12 digits)"
                                // }
                                }}
                                render={({ field: { ref, ...field }}) =><PhoneInput
                                {...field}
                                value={field.value}
                                onChange={field.onChange}
                                innerRef={ref}
                                searchPlaceholder={"بحث"}
                                enableSearch={true}
                                disableSearchIcon={true}
                                country={'sy'}
                                autoFormat={true} 
                                placeholder={"رقم الموبايل"}
                                containerClass='text-cairo'
                                searchClass='text-center'
                                searchStyle={{direction : "rtl", margin : "0", fontSize : "14px"}}
                                dropdownClass='text-end scrollbar-style'
                                dropdownStyle={{borderRadius : "5px"}}
                                inputProps={{
                                    name: 'phoneNumber',
                                    dir : "ltr",
                                }}
                                />
                                }
                            />
                            </div>
                            {errors.phoneNumber && <p className='text-error text-danger'>{errors.phoneNumber.message}</p>}
                        </li>
                        <li class="mb-2 col-md-6 col-12">
                            <Controller
                            name="country"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <Select
                                className="select-country"
                                // classNamePrefix="نوع الوسائط"
                                // menuIsOpen={true}
                                isClearable={false}
                                isRtl={true}
                                isSearchable={true}
                                name="country"
                                options={arabicCountries}
                                placeholder="البلد"
                                {...field}
                                />
                            )}
                            />
                            {errors.country && errors.country.type === "required" && (
                            <div className="text-danger">البلد مطلوب</div>
                            )}
                        </li>
                        <li class="mb-2 col-md-6 col-12">
                            <input className='input-form' 
                                type="password" 
                                name="password" 
                                placeholder="كلمة السر" 
                                {...register("password", { required: true, pattern : /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/ })}
                            />
                            {errors.password && errors.password.type === "required" && (
                            <div className='text-danger'>الحد الأدنى ثمانية أحرف، على الأقل حرف واحد، رقم واحد وحرف خاص</div>
                            )}
                            {errors.password && errors.password.type === "pattern" && (
                            <div className='text-danger'>الحد الأدنى ثمانية أحرف، على الأقل حرف واحد، رقم واحد وحرف خاص</div>
                            )}
                        </li>
                        <li class="mb-2 col-md-6 col-12">
                            <input className='input-form' 
                                type="password" 
                                name="confirmPassword"
                                placeholder="تأكيد كلمة السر" 
                                {...register("confirmPassword", { required: true,})}
                            />
                            {errors.confirmPassword && errors.confirmPassword.type === "required" && (
                            <div className='text-danger'>تأكيد كلمة السر مطلوبة</div>
                            )}
                            {!errors.password && watch("password") !== watch("confirmPassword") && (
                            <div className='text-danger'>كلمة السر غير متطابقة</div>
                            )}
                        </li>
                    </ul>
                    <button className='registration-btn' type="submit">تسجيل الدخول</button>
                </form>
            </div>
        </div>
        </>
    )
}