import React, { useEffect, useState } from "react";
import poster3 from "../../img/posters/LTV1.webp";
import logo from "../../img/logos/LTV-logo_Approved04-1-1536x1411.png";
import MediaEpisodes from "../MediaEpisodes/MediaEpisodes";
import { SwiperSlide } from "swiper/react";
import MediaSlider from "../MediaSlider/MediaSlider";
import MediaItem from "../MediaItem/MediaItem";
import MediaItemSlider from "../MediaItemSlider/MediaItemSlider";
import { Link } from "react-router-dom";
import MediaItemSlider2 from "../MediaItemSlider2/MediaItemSlider2";
import { useApi } from "../Store/ApiContext";
import BlogItemSlider from "../BlogItemSlider/BlogItemSlider";

export default function Home() {

  const {getData} = useApi();
  
  const [data, setData] = useState({});
  const [dataArticles, setDataArticles] = useState([]);
  

  async function getMedia() {
      const data = await  getData(`season`);
      if (data) {
          setData(data)
      }
      console.log(data);
  }

  async function getArticles() {
    const data = await  getData("articles", 0, 20);
    if (data) {
      setDataArticles(data.articles)
    }
    console.log(data);
}

  useEffect(() => {
      getMedia();
      getArticles()
  }, []);

  // getData()
  return (
    <>
      <section className="home">
        <MediaSlider />

        { data?.categories && data?.categories.reverse().map((ele, i) => <section className="media-section" key={i}>
          <div className="header-media-section d-flex justify-content-between align-items-center" >
            <div>
              <h2 className="text-white">{ele.categoryName}</h2>
            </div>
            <div >
              <Link to={`/media/${ele.categoryRoute}`}>
                <button className="all-category-btn">مشاهدة الكل</button>
              </Link>
            </div>
          </div>
          {i % 2 ? <MediaItemSlider2 data={data.data[ele.categoryRoute]} /> : <MediaItemSlider data={data.data[ele.categoryRoute]} />}
          {/* <MediaItemSlider2 data={data.data["series"]} /> */}

        </section> )  }

        <section className="media-section">
          <div className="header-media-section d-flex justify-content-between align-items-center" >
            <div>
              <h2 className="text-white">الأخبار</h2>
            </div>
            <div >
              <Link to={`/blog`}>
                <button className="all-category-btn">مشاهدة الكل</button>
              </Link>
            </div>
          </div>
          {dataArticles?.length && <BlogItemSlider data={dataArticles} />}
        </section>    
      </section>      
    </>
  );
}
