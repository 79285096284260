import React from 'react'

export default function ContactUsPage() {

    const onSubmit = (e) => {
        e.preventDefault()
    }

  return (
    <section className='mt-5 pt-0 pt-lg-5 pb-5'>
    <div className='container mb-5 contact-us '>
        <h2 className='fs-1 fw-bolder mb-5'>إتصل بنـا</h2>
        <form className=' text-center' onSubmit={onSubmit}>
            <input className=' mb-3' type="text" placeholder="الأسم"  />
            <input className=' mb-3' type="text" placeholder="البريد الألكتروني"  />
            <textarea className=' mb-3' rows={4}  placeholder="الرسالة"/>
            <button className='btn-form'>
                إرسال
            </button>
        </form>
    </div>
  
    
    </section>
  )
}
