import { faClock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import blogImg1 from "../../img/blog/blog-img-1.jpeg";
import blogImg2 from "../../img/blog/blog-img-2.jpeg";
import moment from 'moment';
import { Link } from 'react-router-dom';

export default function BlogMiniDetails({data, title}) {
  return (
    <>
    <div className='recently-blog'>
        <div className='header-recently-blog'>
                <h3 className='fs-6 text-white'>
                {title}
                </h3>
            <hr className='m-0 text-white' />
        </div>
        <div className='body-recently-blog'>
            {data.length && data.map((ele, i) =>  <div className='card-recently-blog' key={i}>
                <div className='img-recently'>
                    <Link to={`/blog-details/${ele.id}`} >
                        <img src={ele.imageArticle} className='' alt={ele.titleArticle} />
                    </Link>
                </div>
                <div className='content-recently'>
                    <p className='date-recently'><FontAwesomeIcon icon={faClock} className='pe-1' /> {moment(ele.createdAt).format("L")} | {moment(ele.createdAt).format("LT")} </p>
                    <Link to={`/blog-details/${ele.id}`} >
                        <p className='desc-recently'>{ele.titleArticle}</p>
                    </Link>
                </div>
            </div> )}
        </div>
    </div>
    </>
  )
}
