import React, { useEffect, useState } from 'react'
import backgroundPicture from '../../img/blog/radu-prodan-W14oKLRJpY8-unsplash.jpg';
import SideBar from '../SideBar/SideBar';
import blogImg1 from "../../img/blog/blog-img-1.jpeg";
import blogImg2 from "../../img/blog/blog-img-2.jpeg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import BlogMiniDetails from '../BlogMiniDetails/BlogMiniDetails';
import { Link } from 'react-router-dom';
import { useApi } from '../Store/ApiContext';
import BlogItems from '../BlogItems/BlogItems';
import BlogHeader from '../BlogHeader/BlogHeader';
import { string } from 'joi';

export default function Blog() {

  const {getData} = useApi();
  
  const [data, setData] = useState({});
  const [pageNumber, setPageNumber] = useState(1);

  async function fetchData() {
    const data = await getData(`latestArticles`);
    console.log(data);
    setData(data)
}

function setDataInLocalStorage(data) {
  localStorage.setItem("data", JSON.parse(data))
}



useEffect(() => {
    fetchData()
}, []);

  return (
    <>
    {data?.latestArticles?.length && data?.articlesSlider?.length ? 
      <section className='blog'>
        {/* <hr className='line-navbar-blog'/> */}
        <h2 className='d-block d-lg-none text-center fw-bolder pt-3 mb-3'>الأخبار الفنية</h2>
        <div className='header-blog d-none d-md-inline-block'>
        {data?.latestArticles && <BlogHeader data={data.articlesSlider} setDataInLocalStorage={setDataInLocalStorage}/>}
          {/* <div className='row gx-2'>
            <div className='col-xl-3 ' > 
              <div className='small-card d-md-flex d-xl-inline-block'>
              <Link to={"/blog-details/1"}>
                <div className='mb-2 small-card-slider'>
                    <img className='w-100 img-card-slider' src={blogImg1} alt="" />
                    <div className='layer-gradient-img-card'>
                      <div className='content-on-img-card'>
                        <div className='d-flex justify-content-between align-items-center pb-2 header-content-on-img-card'>
                          <div className="tag-card" style={{backgroundColor : "#DFCE6D"}}>
                            فنون
                          </div>
                          <div className='d-flex justify-content-between align-items-center time-card'>
                            <FontAwesomeIcon icon={faClock} className='pe-1 ' />
                            <p>15:00</p>
                          </div>
                        </div>
                        <h6 className=''>
                            إعلانات يومية وربح على نسب المبيعات قصة مثيرة لجدة أربعينية: ...
                        </h6>
                      </div>
                      
                    </div>
                  </div>
                  </Link>
                <Link to={"/blog-details/1"}>
                  <div className='ms-md-2 ms-xl-0 mb-2 mb-xl-0 small-card-slider'>
                    <img className='w-100 img-card-slider' src={blogImg1} alt="" />
                    <div className='layer-gradient-img-card'>
                        <div className='content-on-img-card'>
                          <div className='d-flex justify-content-between align-items-center pb-2 header-content-on-img-card'>
                            <div className="tag-card" style={{backgroundColor : "#DFCE6D"}}>
                              فنون
                            </div>
                            <div className='d-flex justify-content-between align-items-center time-card'>
                              <FontAwesomeIcon icon={faClock} className='pe-1 ' />
                              <p>15:00</p>
                            </div>
                          </div>
                          <h6 className=''>
                              إعلانات يومية وربح على نسب المبيعات قصة مثيرة لجدة أربعينية: ...
                          </h6>
                        </div>
                        
                      </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className='col-xl-6 '>
              <Link to={"blog-details/1"}>
                <div className='big-card mb-2 mb-xl-0'>
                  <img className='w-100 img-card-slider' src={blogImg2} alt="" />
                  <div className='layer-gradient-img-card'>
                        <div className='content-on-img-card'>
                          <div className='d-flex justify-content-between align-items-center pb-2 header-content-on-img-card'>
                            <div className="tag-card" style={{backgroundColor : "#DFCE6D"}}>
                              فنون
                            </div>
                            <div className='d-flex justify-content-between align-items-center time-card'>
                              <FontAwesomeIcon icon={faClock} className='pe-1 ' />
                              <p>15:00</p>
                            </div>
                          </div>
                          <h6 className=''>
                              إعلانات يومية وربح على نسب المبيعات قصة مثيرة لجدة أربعينية: ...
                          </h6>
                        </div>
                        
                      </div>
                </div>
              </Link>
            </div>
            <div className='col-xl-3 '>
            <div className='small-card d-md-flex d-xl-inline-block '>
                <div className='mb-2 small-card-slider '>
                    <img className='w-100 img-card-slider' src={blogImg1} alt="" />
                    <div className='layer-gradient-img-card'>
                      <div className='content-on-img-card'>
                        <div className='d-flex justify-content-between align-items-center pb-2 header-content-on-img-card'>
                          <div className="tag-card" style={{backgroundColor : "#DFCE6D"}}>
                            فنون
                          </div>
                          <div className='d-flex justify-content-between align-items-center time-card'>
                            <FontAwesomeIcon icon={faClock} className='pe-1 ' />
                            <p>15:00</p>
                          </div>
                        </div>
                        <h6 className=''>
                            إعلانات يومية وربح على نسب المبيعات قصة مثيرة لجدة أربعينية: ...
                        </h6>
                      </div>
                      
                    </div>
                  </div>
                
                <div className='ms-md-2 ms-xl-0 mb-2 mb-xl-0 small-card-slider'>
                  <img className='w-100 img-card-slider' src={blogImg1} alt="" />
                  <div className='layer-gradient-img-card'>
                      <div className='content-on-img-card'>
                        <div className='d-flex justify-content-between align-items-center pb-2 header-content-on-img-card'>
                          <div className="tag-card" style={{backgroundColor : "#DFCE6D"}}>
                            فنون
                          </div>
                          <div className='d-flex justify-content-between align-items-center time-card'>
                            <FontAwesomeIcon icon={faClock} className='pe-1 ' />
                            <p>15:00</p>
                          </div>
                        </div>
                        <h6 className=''>
                            إعلانات يومية وربح على نسب المبيعات قصة مثيرة لجدة أربعينية: ...
                        </h6>
                      </div>
                      
                    </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className="container mt-0 text-start">
          <div className='body-blog row gx-4'>
            <div className='col-xl-8 mt-4'>
              <BlogItems setDataInLocalStorage={setDataInLocalStorage}/>
            </div>
            <div className='col-xl-4'>
              {/* <div className='recently-blog'>
                <div className='header-recently-blog'>
                  <h3 className='fs-6 text-white'>
                    أحدث الأخبار
                  </h3>
                  <hr className='m-0 text-white' />
                </div>
                <div className='body-recently-blog'>
                  <div className='card-recently-blog'>
                    <div className='img-recently'>
                      <img src={blogImg1} className='' alt="" />
                    </div>
                    <div className='content-recently'>
                      <p className='date-recently'><FontAwesomeIcon icon={faClock} className='pe-1' /> 2023-12-11 | 15:00 </p>
                      <p className='desc-recently'>شركة روتانا تحصل على أكبر عقود للإعلانات الخارجية في العالم</p>
                    </div>
                  </div>
                  <div className='card-recently-blog'>
                    <div className='img-recently'>
                      <img src={blogImg2} className='' alt="" />
                    </div>
                    <div className='content-recently'>
                      <p className='date-recently'><FontAwesomeIcon icon={faClock} className='pe-1' /> 2023-12-11 | 15:00 </p>
                      <p className='desc-recently'>اليسا بلقاء مع أ. سالم الهندي في مقرروتانا بالمملكة العربية السعودية</p>
                    </div>
                  </div>
                  <div className='card-recently-blog'>
                    <div className='img-recently'>
                      <img src={blogImg1} className='' alt="" />
                    </div>
                    <div className='content-recently'>
                      <p className='date-recently'><FontAwesomeIcon icon={faClock} className='pe-1' /> 2023-12-11 | 15:00 </p>
                      <p className='desc-recently'>أحمد الفيشاوي يوجه رسالة لوالدته سمية الالفي</p>
                    </div>
                  </div>
                </div>
              </div> */}
              {data?.latestArticles && <BlogMiniDetails data={data.latestArticles} title={"اخر الأخبار"} />}
            </div>
          </div>
        </div>
        
      </section> : <div className='vh-100'></div>}
    </>
  )
}
