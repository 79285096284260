import React from 'react'
import logo from "../../img/logos/LTV-logo_Approved04-1-1536x1411.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import imgGooglePlay from "../../img/footer/footer-googlePlay.jpg";
import imgPlayStore from "../../img/footer/footer-playStore.jpg";
import { Link } from 'react-router-dom';
import { clarity } from 'react-microsoft-clarity';

export default function Footer() {

    if (process.env.NODE_ENV === 'production') clarity.init('m1lz0v2d3x');

  return (
    <>
    <section className='footer'>
        <div className=' footer-item'>
            <div className='item-footer info-footer'>
                <div className=''>
                    <Link to={"/"}>
                        <img className='logo-footer-item' src={logo} alt="ltv" />
                    </Link>
                </div>
                <ul className='d-flex justify-content-start'>
                    <li className='pe-3 py-4'><Link to={"/frequency"}><h4>الترددات</h4> </Link></li>
                    <li className='pe-3 py-4'><Link to={"/about-ltv"}><h4>عن ال.تي.في</h4> </Link></li>
                    <li className='pe-3 py-4'><Link to={"/privacy-policy"}><h4>سياسة الخصوصية</h4> </Link></li>
                    <li className='pe-3 py-4'><Link to={"/contact-us"}><h4>إتصل بنا</h4> </Link></li>
                </ul>
                <div className='desc-footer'>
                    <p>  ال.تي.في  2023© جميع مقاطع الفيديو والعروض على هذا الموقع هي علامات تجارية ، وجميع الصور 
                        والمحتويات ذات الصلة هي ملك لشركة ال.تي.في  ،ويحظر تمامًا نسخ هذا المحتوي.
                    </p>
                </div>
            </div>
            <div className='item-footer media-footer'>
                <div>
                    <h5 className='mb-4'>تابعنا علي</h5>
                </div>
                <ul className='d-flex justify-content-start'>
                    <li><a target="_blank" rel='noreferrer' href="https://www.facebook.com/LTV.main"><FontAwesomeIcon className='social-icon fs-3 pe-3' icon={faFacebookF} /></a></li>
                    <li><a target="_blank" rel='noreferrer' href="https://twitter.com/Ltv_Main"><FontAwesomeIcon className='social-icon fs-3 pe-3' icon={faXTwitter} /></a></li>
                    <li><a target="_blank" rel='noreferrer' href="https://www.youtube.com/channel/UCk60HSbfpy-_kUKH0CU0asg"><FontAwesomeIcon className='social-icon fs-3 pe-3' icon={faYoutube} /></a></li>
                </ul>
            </div>
            {/* <div className='item-footer download-footer'>
                <div>
                    <h5 className='mb-4'>احصل عليه من</h5>
                </div>
                <div>
                    <a target="_blank" rel='noreferrer' href="https://play.google.com/store/apps/details?id=tv.lanacanal.lana&hl=en_GB&gl=US&showAllReviews=true"><img className='pe-3 download-img' src={imgGooglePlay} alt="Google Play" /></a>
                    <a target="_blank" rel='noreferrer' href="https://apps.apple.com/tr/app/ltv-%D8%A5%D9%84-%D8%AA%D9%8A-%D9%81%D9%8A/id1544665881"><img className='pe-3 download-img' src={imgPlayStore} alt="Play Store" /></a>
                </div>
            </div> */}
        </div>
    </section>
    </>
  )
}
