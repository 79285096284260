import React from 'react'
import poster1 from "../../img/posters/LTV11.webp"
import poster2 from "../../img/posters/LTV9.webp"
import poster3 from "../../img/posters/LTV1.webp"
import playShape from "../../img/shapes/play-shape.png";
import MediaItem from '../MediaItem/MediaItem';
import logo from "../../img/logos/LTV-logo_Approved04-1-1536x1411.png";
import { Swiper, SwiperSlide } from 'swiper/react';

// import required modules
import { Pagination } from 'swiper/modules';
import MediaSlider from '../MediaSlider/MediaSlider';
import GridItem from '../GridItem/GridItem';



export default function Media() {

    const x = ["المتوحش", "فورملا",  "نصي التاني"] 
    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + x[index] + '</span>';
        },
      };

  return (
    <>
    <section className='media' >
        {/* <div className='header-media'>
            <img className='poster-media' src={poster} alt="poster" />
            <div className="layer2"></div>
            <div className="layer1">
                <div className='info-media'>
                    <img className="logo-info-media" src={logo} alt="LTV" />
                    <h2 className='title-media'>المتوحش</h2>    
                    <ul className='d-flex mb-3 list-info-media'>
                        <li className='me-2'>كوميدي</li>
                        <li className='list-info-1'>عائلي</li>
                        <li className='list-info-1'>رومانسي</li>
                    </ul>
                    <ul className='d-flex mb-1 list-info'>
                        <li className='me-2'>الموسم 1</li>
                        <li className='list-info-2'>فبراير 2022</li>
                    </ul>
                    <div className='play-media d-flex align-items-center'>
                        <img className='img-play-media' src={playShape} alt="play" />
                        <p className='title-play'>شاهد الأن</p>
                    </div>
                    <p className='desc-media d-none d-lg-block'>بعد مرور سنوات على اختطافه عندما كان طفلا من عائلته الثرية والعيش في الزقاق والشوارع، يجد يمان نفسه مجبرا على إعادة تعريف حياته عندما يعود إلى منزله.</p>
                </div>
            </div>
        </div> */}

      <MediaSlider endPoint={"slider"}/>
    </section>
    <MediaItem/>
    </>
  )
}
