import logo from './logo.svg';
import './App.css';
import { ScrollRestoration } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Media from './components/Media/Media';
import Footer from './components/Footer/Footer';
import TopHeader from './components/TopHeader/TopHeader';
import moment from 'moment';
import 'moment/locale/ar-ly';
import MediaDetails from './components/MediaDetails/MediaDetails';
import { useCallback, useEffect } from 'react';
import NotFound from './components/NotFound/NotFound';
import SideBar from './components/SideBar/SideBar';

//Start clearCache
import packageJson from "../package.json";
import { getBuildDate } from './utils/utils';
import withClearCache from './ClearCache';
import axios from 'axios';
import GoogleAnalyticsApi from './components/GoogleAnalytics/GoogleAnalytics';
import { useApi } from './components/Store/ApiContext';
//End clearCache

const ClearCacheComponent = withClearCache(App);  
export default function MainApp() {
  return <ClearCacheComponent />;
}



export function App() {

  let getKey = useCallback(
    (location, matches) => {
      window.scrollTo(0, 0);
    },
    []
);
  moment.locale('ar-ly');
  

  return (
    <>
      <TopHeader/>
      <Navbar/>
      <SideBar/>
      <ScrollRestoration getKey={getKey}/>
      {/* <GoogleAnalyticsApi/> */}
    </>
  );
}


