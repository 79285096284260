import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faArrowRightFromBracket, faArrowRightToBracket, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import PopupLogin from '../PopupLogin/PopupLogin';
import { useApi } from '../Store/ApiContext';

export default function TopHeader() {

  const {togglePopUp, token, setToken} = useApi();

  const [time, setTime] = useState("")

  setInterval(() => {
    setTime(moment().format('MMMM Do YYYY, h:mm:ss a'))
  }, 1000);
  
  function logout() {
    localStorage.removeItem("token");
    // setToken("");
    window.location.reload();
  }
  return (
    <>
      <div className='top-header'>
        <div className='d-flex justify-content-between align-item-center content-to-header'>
          <div>
            <p>
              {time}
            </p>
          </div>
          <div>
            <div className='d-flex justify-content-start'>
              {!token ? 
                <div className='d-flex justify-content-center cursor-pointer' onClick={() => togglePopUp("popup-login")}><span className='me-2'>تسجيل الدخول</span><FontAwesomeIcon className='fs-6 me-4' icon={faArrowRightToBracket} /></div>
                :
                <div className='d-flex justify-content-center cursor-pointer' onClick={() => logout()}><span className='me-2'>تسجيل الخروج</span><FontAwesomeIcon className='fs-6 me-4' icon={faArrowRightFromBracket} /></div>
              }
              <ul className='d-lg-flex justify-content-start d-none'>
              <li><a target="_blank" rel='noreferrer' href="https://www.facebook.com/LTV.main"><FontAwesomeIcon className='social-icon fs-6 me-3' icon={faFacebookF} /></a></li>
              <li><a target="_blank" rel='noreferrer' href="https://twitter.com/Ltv_Main"><FontAwesomeIcon className='social-icon fs-6 me-3' icon={faXTwitter} /></a></li>
              <li><a target="_blank" rel='noreferrer' href="https://www.youtube.com/channel/UCk60HSbfpy-_kUKH0CU0asg"><FontAwesomeIcon className='social-icon fs-6 me-3' icon={faYoutube} /></a></li>
            
              </ul>
            </div>
          </div>
        </div>
        <hr className='line-top-header'/>
      </div>
      <PopupLogin />
    </>
  )
}
