import { faClock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { faFacebookF, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import BlogMiniDetails from '../BlogMiniDetails/BlogMiniDetails';
import { useApi } from '../Store/ApiContext';
import { useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import { FacebookShareButton, TwitterShareButton } from 'react-share';

export default function BlogDetails() {

    const {getData} = useApi();
    const {id} = useParams();
    const location = useLocation()

    const [data, setData] = useState({});
    const [articleDetails, setArticleDetails] = useState({});


    async function getArticle() {
        const data = await getData(`article/${id}`);
        console.log(data);
        setArticleDetails(data)
    }

    async function fetchData() {
        console.log(articleDetails.categoryArticle);
        const data = await getData(`latestAndRelatedArticles/${articleDetails.categoryArticle}`);
        console.log(data);
        setData(data)
    }
    
    useEffect(() => {
        getArticle()
    }, [location.pathname]);

    useEffect(() => {
        if (articleDetails?.id) {
            fetchData();
        }
    }, [articleDetails])
  

  return (
    <section className='blog-details'>
        <hr className='line-navbar-blog-details' />
        <div className='container text-start'>
            <div className="row gx-4">
                <div className="col-xl-8">
                    <div className='blog-item'>
                        <div className='title-blog-item'>
                            <h3>{articleDetails.titleArticle}</h3>
                        </div>
                        <div className='d-flex justify-content-between align-items-center mt-3 mb-2'>
                            <div className="tag-card" style={{backgroundColor : "#DFCE6D"}}>
                                {articleDetails.categoryArticle}
                            </div>
                            <div className='date-blog-item'>
                                <p className='ps-1'><FontAwesomeIcon icon={faClock} className='pe-1' /> {moment(articleDetails.createdAt).format("L")} | {moment(articleDetails.createdAt).format("LT")} </p>
                            </div>
                        </div>
                        <div className='img-blog-item'>
                            <img src={articleDetails.imageArticle} className='' alt={articleDetails.titleArticle} />
                        </div>
                        <div className="paragraph-blog-item" dangerouslySetInnerHTML={{__html : articleDetails.bodyArticle}}>
                        </div>
                        <ul className='d-flex justify-content-end mt-5 pt-5'>
                            <li>
                                <FacebookShareButton
                                url={window.location.href}
                            >
                                <FontAwesomeIcon className='social-icon fs-3 pe-3' icon={faFacebookF} />
                            </FacebookShareButton>
                                
                            </li>
                            <TwitterShareButton
                                url={window.location.href}
                            >
                                <FontAwesomeIcon className='social-icon fs-3 pe-3' icon={faXTwitter} />
                            </TwitterShareButton>
                            {/* <li>
                                <FacebookShareButton
                                onClick={() => document.querySelector('meta[property="og:image"]').setAttribute("content", articleDetails.imageArticle)}
                                url={window.location.href}
                            >
                                <FontAwesomeIcon className='social-icon fs-3 pe-3' icon={faShare} />
                            </FacebookShareButton>
                            </li> */}
                            
                        </ul>
                    </div>

                </div>
                <div className="col-xl-4">
                    <div className='space-adss'>

                    </div>
                    {data?.latestArticles && <>
                        <BlogMiniDetails data={data.relatedArticles} title={"اخبار متعلقة"} />
                        <BlogMiniDetails data={data.latestArticles} title={"اخر الأخبار"} />
                    </> }

                </div>
            </div>
        </div>
        
    </section>
  )
}
