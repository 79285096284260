import React, { useEffect, useRef, useState } from 'react'
import blogImg1 from "../../img/blog/blog-img-1.jpeg";
import blogImg2 from "../../img/blog/blog-img-2.jpeg";
import { useApi } from '../Store/ApiContext';
import Pagination from "rc-pagination";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

export default function BlogItems({}) {

  const {getData} = useApi();
  
  const [data, setData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const bodyArticleRef = useRef(null)

  async function fetchData() {
    const data = await getData(`articles`, pageNumber, 10);
    console.log(data);
    setData(data)
}

  useEffect(() => {
      fetchData();
  }, [pageNumber]);

  return (
    <>
     {data?.articles?.length && data.articles?.map((ele, i) => 
      <div className='card-blog d-none d-md-flex' key={i}>
        <div className="img-card">
          <Link to={`/blog-details/${ele.id}`}>
            <img  src={ele.imageArticle} alt={ele.titleArticle} />
          </Link>
        </div>
        <div className='content-card'>
          <div className="tag-card" style={{backgroundColor : "#DFCE6D"}}>
            {ele.categoryArticle}
          </div>
          <Link to={`/blog-details/${ele.id}`}>
            <div className='title-card'>
              <h3 className='fs-6'>{ele.titleArticle}</h3>
            </div>
          </Link>
          <div className='desc-card'>
            {/* <p className=''>اعلنت شركة روتانا ساينز حصولها على أكبر عقود للإعلانات الخارجية في العالم، واعلنت الشرك... {data[0].bodyArticle}</p> */}
            <p className='fs-6' dangerouslySetInnerHTML={{__html: ele.bodyArticle.slice(0, 70) + "..."}}></p>
          </div>
        </div>
      </div>
    )}
    {data?.articles?.length && data.articles?.map((ele, i) => 
    <div className='card-blog-mobile d-md-none d-inline-block'>
      <Link to={`/blog-details/${ele.id}`}>
        <div className='mb-2 blog-card-slider'>
          <img className='w-100 img-card-slider' src={ele.imageArticle} alt="" />
          <div className='layer-gradient-img-card'>
            <div className='content-on-img-card'>
              <div className='d-flex justify-content-between align-items-center pb-2 header-content-on-img-card'>
                <div className="tag-card" style={{backgroundColor : "#DFCE6D"}}>
                  {ele.categoryArticle}
                </div>
                <div className='d-flex justify-content-between align-items-center time-card'>
                  {/* <FontAwesomeIcon icon={faClock} className='pe-1 ' /> */}
                  {/* <p>{moment(ele.createdAt).format("LT")}</p> */}
                </div>
              </div>
              <h6 className=''>
                  {ele?.titleArticle?.slice(0, 60)}{ele?.titleArticle?.length > 60 ? "..." : "" } 
                  {/* إعلانات يومية وربح على نسب المبيعات قصة مثيرة لجدة أربعينية: ... */}
              </h6>
            </div>
          </div>
        </div>
      </Link>
      </div>
    )}

      <div className="d-flex justify-content-between align-items-center footer-table mt-4" dir="ltr">
          <Pagination className="ant-pagination" locale={"ar-ly"} current={pageNumber} defaultCurrent={1} total={data?.articleCount} pageSize={10} onChange={(current)=> {setPageNumber(current)}}/>
        </div>
    </>
  )
}
