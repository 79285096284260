import React, { useEffect, useState } from 'react'
import { useApi } from '../Store/ApiContext';
import { useForm } from 'react-hook-form';
import { Link, Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import $ from 'jquery';
import { Bounce, toast } from 'react-toastify';

export default function LoginForm() {
    
    const { responseApi, togglePopUp, token, setToken } = useApi();

    const location = useLocation()

    const navigate = useNavigate()

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        const resData = await handleApiResponse(data);
        // console.log(resData);
    }
    function resetInputs() {
        reset(
            {
                userName : "",
                password : ""
            }
        )
    }

    async function handleApiResponse(data) {
        console.log(data);
        const result = await responseApi("member/login", "patch", data)
        console.log(result);
        if (result?.message === "success") {
            localStorage.setItem("token", result.token)
            window.location.reload()
            // setToken(result.token)
            // resetInputs()
            // navigate("/");
            if (location.pathname != "/broadcasting") {
                togglePopUp("popup-login");
            }
            
        } else if (result?.message === "There is something wrong with this password") {
            toast("يوجد شئ خطأ بكلمة السر", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
        } else if (result?.message === "There is no account with email or mobile number") {
            toast("لا يوجد حساب لهذا البريد الإلكتروني او رقم الموبايل", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
        }
        
    }

    return (
        <>
        <div className='login'>
            <div className='login-form'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ul>
                        <li class="mb-2">
                            <input className='' 
                                type="text" 
                                name="userName" 
                                placeholder="البريد الالكتروني أو رقم الموبايل"
                                {...register("userName", { required: true })}
                            />
                            {errors.userName && errors.userName.type === "required" && (
                            <div className='text-danger'>البريد الالكتروني أو رقم الموبايل مطلوب</div>
                            )}
                        </li>
                        <li class="mb-2">
                            <input 
                                className='' 
                                type="password" 
                                name="password" 
                                placeholder="كلمة السر" 
                                {...register("password", { required: true})}
                            />
                            {errors.password && errors.password.type === "required" && (
                            <div className='text-danger'>كلمة السر مطلوبة</div>
                            )}
                        </li>
                    </ul>
                    <div>
                        <button className='login-btn' type="submit">تسجيل الدخول</button>
                        <p className='text-white-50 mt-3 fs-6'>ليس لديك حساب؟<Link to={"/registration"}><span className='ms-1' onClick={() => location.pathname != "/broadcasting" ? togglePopUp("popup-login") : () => {} }> تسجيل حساب جديد.</span></Link></p>
                    </div>
                </form>
            </div>
        </div>
        </>
    )
}