import React from 'react'

export default function FrequenciesPage() {

    function Header() {
        return (
          <div className={"header"}>
            <a href="https://l-television.com/LTV/%d8%a7%d9%84%d8%aa%d8%b1%d8%af%d8%af%d8%a7%d8%aa" data-ur1313m3t="true">الترددات</a>
          </div>
        );
      }
      
      function Title() {
        return (
          <div className={"title"}>
            ال.تي.في
          </div>
        );
      }
      
      function FrequencyInfo() {
        return (
          <div className={"frequencyInfo"}>
            <p>NileSat<br />Frequency: 11277<br />Polarization: V<br />Signal Rate: 27500<br />FEC: 5/6</p>
          </div>
        );
      }
      
      function Column({ children }) {
        return (
          <div className="elementor-column elementor-col-33 elementor-top-column elementor-element">
            <div className="elementor-widget-wrap">{children}</div>
          </div>
        );
      }

    return (
        <section className='mt-5 pt-0 pt-lg-5 pb-5 '>
            <div className='container mb-5'>
                <div className="header-frequency">
                    <div className={"title"}>
                    <h2 className='fw-bolder mb-5 fs-1'> الترددات</h2>
                    </div>
                </div>
                <div className='body-frequency'>
                    <div className={"frequencyInfo"}>
                        <ul className='text-center'>
                            <li>
                                <h5 >NileSat</h5>
                            </li>
                            <li>
                                <h5 >Frequency: 11277</h5>
                            </li>
                            <li>
                                <h5 >Polarization: V</h5>
                            </li>
                            <li>
                                <h5 >Signal Rate: 27500</h5>
                            </li>
                            <li>
                                <h5 >FEC: 5/6</h5>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            
        </section>
      );
}