import { faFacebookF, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import {TwitterIcon, FacebookIcon, FacebookShareButton, TwitterShareButton, XIcon } from 'react-share';
// import Dropdown from 'react-overlays/Dropdown'

export default function OverlayShareBtn({children, urlShare, imageUrl}) {
    
    function changeMetaImage() {
        document.querySelector('meta[property="og:image"]').setAttribute('content', imageUrl);
        document.querySelector('meta[property="twitter:image"]').setAttribute('content', imageUrl);
    }

  return (
    <>
    <div className='overlay-share-btn'>
        <Dropdown drop="up-centered">
            <Dropdown.Toggle className="dropdown-social" >
                <div onClick={changeMetaImage}>
                    {children}

                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <div className=' d-flex justify-content-center '>
                <FacebookShareButton 
                    url={urlShare.startsWith("http") ? urlShare : window.location.origin + urlShare} 
                >
                    <FontAwesomeIcon className='social-icon fs-4 p-2' icon={faFacebookF} />
                </FacebookShareButton>
                <TwitterShareButton
                    url={urlShare.startsWith("http") ? urlShare : window.location.origin + urlShare}
                >
                    <FontAwesomeIcon className='social-icon fs-4 p-2' icon={faXTwitter} />
                </TwitterShareButton>
            </div>
            </Dropdown.Menu>
        </Dropdown>
    </div>
    
    </>
  )
}







