import React from 'react'

export default function AboutLtvPage() {
  return (
    <section className='mt-5 pt-0 pt-lg-5 pb-5'>
        <div className='container mb-5'>
            <h2 className='fs-1 fw-bolder mb-5'>عن ال.تي.في</h2>
            <div>
                <p className='lh-lg text-start'>
                ال.تي.في قناة تلفزيونية عربيـــة فضائية خاصة ، جرى إطلاقها من العاصمة اللبنانية بيروت في أيار 2018 . تبث باللغة العربية على قمر النايلسات تردد (12360 V – 11373 H) ، وهي متاحة مجانا وبصورة عالية الدقة High Definition ، إضافةً إلى توفرها على وسائل التواصل الإجتماعي والمنصات الرقمية والــــIPTV لتلبية رغبات مشاهديها حول العالم…تزود ال.تي.في مشاهديها بباقة من المسلسلات الدرامية العربية ، والسورية تحديدا ، بفئاتها المختلفة على مدار اليوم ، إضافةً إلى الفواصل الترفيهية والبرامج الفنيــة الممتعة التي يجري إنتاجها محلياً تتمتع ال.تي.في بمضمون تخصصي يتناول جوانب الحياة المختلفة ويعنى بالجانب الإجتماعي للأسرة العربية..تحظى ال.تي.في حالياً بأعلى نسبة مشاهدة في سورية رغم عمرها الفتي ، كمــــا تم تكريمها من شركة غوغل لنسبة المشاركة العالية التي حققتها صفحة التلفزيون على موقع اليوتيوب..هذا وتسعى ال.تي.في بشكل مستمر إلى التنوع والتطوير لتواصل تقديمها الأفضل دوما ، وتحافظ على مكانتها المميزة لدى المشاهد
                </p>
            </div>
        </div>
        
    </section>
  )
}
